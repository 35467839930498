import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import Divider from '@mui/material/Divider'

import { useServices } from '../shared/react/components/Services'
import clsx from 'clsx'
import { AppVersion } from './AppVersion'
import { AutoLink } from '../routes'
import { translate } from '../shared/react/components/I18n'

const MenuBurger = () => {
  const { user } = useServices()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return <div className={clsx('menu--nav', 'fade-in', { active: anchorEl !== null })}>
    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup={'true'}
      aria-label={'Main Menu'}
      aria-expanded={open ? 'true' : undefined}
      onClick={open ? handleClose : handleClick}
    >
      <MenuIcon />
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem key={'version-header'}><AppVersion faint={false} /></MenuItem>
      <MenuItem key={'link-root'} onClick={handleClose}><AutoLink to={'/'}>{translate('title.dashboard')}</AutoLink></MenuItem>
      <MenuItem key={'link-chat'} onClick={handleClose}><AutoLink to={'/chat'}>{translate('title.chat')}</AutoLink></MenuItem>
      <MenuItem key={'link-video'} onClick={handleClose}><AutoLink to={'/three'}>{translate('title.three')}</AutoLink></MenuItem>
      <MenuItem key={'link-video'} onClick={handleClose}><AutoLink to={'/video/testing'}>{translate('title.video.client')}</AutoLink></MenuItem>
      <MenuItem key={'link-video-host'} onClick={handleClose}><AutoLink to={'/video/testing/host'}>{translate('title.video.host')}</AutoLink></MenuItem>
      <MenuItem key={'link-users'} onClick={handleClose}><AutoLink to={'/data/user'}>{translate('title.users')}</AutoLink></MenuItem>
      <MenuItem key={'link-forms'} onClick={handleClose}><AutoLink to={'/form/user'}>{translate('title.forms')}</AutoLink></MenuItem>
      <Divider />
      {user?.data.authenticated && [
        <MenuItem key={'link-profile'} onClick={handleClose}><AutoLink to={'/user'}>{translate('title.profile')}</AutoLink></MenuItem>,
        <MenuItem key={'link-logout'} onClick={handleClose}><a onClick={() => user.logout()}>{translate('title.logout')}</a></MenuItem>
      ]}
      {!user?.data.authenticated &&
        <MenuItem key={'link-login'} onClick={handleClose}><a onClick={() => user?.login()}>{translate('title.login')}</a></MenuItem>}
    </Menu>
  </div>
}

export default MenuBurger
