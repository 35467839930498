import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import MenuBurger from '../components/MenuBurger'
import Profile from '../components/Profile'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorHandler } from '../components/ErrorHandler'

import '../assets/container.css'
import { analytics } from '../shared/react/components/Services'


const RootContainer = () => {
    let location = useLocation()

    useEffect(() => {
        analytics.pageView(location.pathname)
    }, [location])
    
    return <ErrorBoundary
        key={'error--wrapper'}
        FallbackComponent={ErrorHandler}
    >
        <div
            key={'root--item'}
            className={'root--container'}
        >
            <div
                key={'nav-container--item'}
                className={'nav--container'}
            >
                <MenuBurger
                    key={'menu--nav'}
                />
                <div
                    key={'nav--item'}
                    className={'root--container__nav'}
                >
                    <section
                        key={'nav--wrapper'}
                        className={'nav--wrapper'}
                    >
                        <Profile
                            key={'nav--profile'}
                        />
                    </section>
                </div>
            </div>
            <div
                key={'outlet--item'}
                className={'root--container__outlet'}
            >
                <section
                    className={'outlet--wrapper'}
                >
                    <Outlet />
                </section>
            </div>
        </div>
    </ErrorBoundary>
}

export {
    RootContainer,
}
