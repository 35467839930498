import {
    __PORPOISE_VERSION__,
    __CLIENT_TYPE__,
    __APPLICATION_MODE__,
    __UNIQUE_DEVICE_ID__,
    __APPLICATION_CLIENT__,
    ApplicationClient,
} from './env'

let transactions = {}
//@ts-ignore
window.postMessageResolve = (id: string, payload: string) => {
    if (id in transactions) {
        clearTimeout(transactions[id].timeout)
        transactions[id].resolve(payload).finally(()=>{
            delete transactions[id]
        })
    } else {
        alert('NO TRANSACTION FOUND TO RESOLVE')
    }
}
//@ts-ignore
window.postMessageReject = (id: string, payload: string) => {
    if (id in transactions) {
        clearTimeout(transactions[id].timeout)
        transactions[id].reject(payload).finally(()=>{
            delete transactions[id]
        })
    } else {
        alert('NO TRANSACTION FOUND TO REJECT')
    }
}
let tid = 1

const postMessageToHost = (type: string, payload: string | number) => new Promise((resolve, reject)=>{
    if (__APPLICATION_CLIENT__ === ApplicationClient.Desktop) {
        return resolve('ok')
    }
    tid ++
    transactions[tid] = {
        resolve,
        reject,
        timeout: setTimeout(()=>{
            reject('timed_out')
        }, 3_000)
    }
    const data = {
        type,
        payload,
        tid,
    }

    try {
        if (__APPLICATION_CLIENT__ === ApplicationClient.IOS) {
            //@ts-ignore
            window.webkit.messageHandlers.postMessageListener.postMessage(JSON.stringify(data))
        } else if (__APPLICATION_CLIENT__ === ApplicationClient.Android) {
            window.postMessage(JSON.stringify(data))
        }
    } catch (e) {
        console.error('postMessage error', e)
        return reject(e)
    }
})

const postStartupMessageToHost = async () => {
    if (!__UNIQUE_DEVICE_ID__) {
        throw 'no_device_id'
    }
    return postMessageToHost('view-startup', __UNIQUE_DEVICE_ID__)
}

const postShutdownMessageToHost = async () => {
    if (!__UNIQUE_DEVICE_ID__) {
        throw 'no_device_id'
    }
    return postMessageToHost('view-shutdown', __UNIQUE_DEVICE_ID__)
}

export {
    postMessageToHost,
    postStartupMessageToHost,
    postShutdownMessageToHost,
}
