import React from 'react'
import { matchRoutes, RouterProvider, createBrowserRouter, NavLink, RouteObject, useNavigate, To } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { RootContainer } from './containers/root'
import { SecureContainer } from './shared/react/containers/SecureContainer'
import { UserGroup } from './shared/types/user'

import './assets/transitions.css'
import { createAutoLinker } from './shared/react/components/AutoLink'

const Lazy = (factory: () => Promise<{
    default: React.ComponentType<any>
}>) => {
    const Component = React.lazy(factory)
    //@ts-ignore: definition
    Component.preload = factory
    return Component
}


const ErrorPage = Lazy(() => import('./pages/ErrorPage'))
const IndexPage = Lazy(() => import('./pages/IndexPage'))
const FormPage = Lazy(() => import('./pages/FormPage'))
const DataPage = Lazy(() => import('./pages/DataPage'))
const ChatPage = Lazy(() => import('./pages/ChatPage'))
const SharePage = Lazy(() => import('./pages/SharePage'))
const ChatHostPage = Lazy(() => import('./pages/ChatHostPage'))
const VideoPage = Lazy(() => import('./pages/VideoPage'))
const VideoHostPage = Lazy(() => import('./pages/VideoHostPage'))
const TestingPage = Lazy(() => import('./pages/TestingPage'))
const ThreePage = Lazy(() => import('./pages/ThreePage'))


const queryClient = new QueryClient()

const chatRoutes = {
    path: 'chat/',
    children: [
        {
            path: ':roomId?',
            element: <ChatPage />,
        },
        {
            path: ':roomId/host',
            element: <ChatHostPage />,
        },
    ],
}
const videoRoutes = {
    path: 'video/',
    children: [
        {
            path: ':roomId?',
            element: <VideoPage />,
        },
        {
            path: ':roomId/host',
            element: <VideoHostPage />,
        },
    ],
}

const testingRoutes = {
    element: <SecureContainer groups={[UserGroup.Staff]}/>,
    children: [
        {
            path: 'testing/',
            element: <TestingPage />,
        },
    ],
}

const routes = [
    {
        path: '/',
        element: <RootContainer />,
        children: [
            {
                index: true,
                element: <IndexPage />,
            },
            {
                path: 'form/',
                children: [
                    {
                        path: 'user',
                        element: <FormPage />,
                    },
                ],
            },
            {
                path: 'three/',
                children: [
                    {
                        index: true,
                        element: <ThreePage />,
                    },
                ],
            },
            videoRoutes,
            chatRoutes,
            testingRoutes,
            { // ADMIN ROUTES
                element: <SecureContainer groups={[UserGroup.Admin]} />,
                children: [
                    {
                        path: 'data/',
                        children: [
                            {
                                path: 'user',
                                element: <DataPage />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'handler/share',
                element: <SharePage />
            },
            {
                path: '*',
                element: <ErrorPage missing />,
            },
        ],
    },
] satisfies RouteObject[]

const router = createBrowserRouter(routes)

const AutoLink = createAutoLinker(routes)

const IndexRouter = () =>
    <QueryClientProvider client={queryClient}>
        {/*
        //@ts-ignore*/}
        <Helmet
            titleTemplate={__APPLICATION_MODE__ === 'pwa'
                ? '%s'
                : 'Dorian - %s'}
        >
            <meta charSet="utf-8" />
            <title>Home</title>
        </Helmet>
        <RouterProvider router={router} />
    </QueryClientProvider>

export default IndexRouter

export {
    AutoLink,
}
