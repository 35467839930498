import {
    __PORPOISE_VERSION__,
    __CLIENT_TYPE__,
    __APPLICATION_MODE__,
} from './shared/react/lib/env'
import React from 'react'
import { render } from 'react-dom'
import { App } from './App'
import { postStartupMessageToHost } from './shared/react/lib/messageHandler'

console.debug('[root]', 'version', __PORPOISE_VERSION__, __CLIENT_TYPE__, __APPLICATION_MODE__, __APPLICATION_CLIENT__)
postStartupMessageToHost().then((response) => {
    console.log('RESOLVED UPDATE FROM HOST APP: ' + response)
})

render(<App />, document.getElementById('root'))
